import { s as sanitizeHTML } from './sanitize-html.util-2ab8a2fb.js';
class ToastController {
  constructor() {
    this.toastWrapper = document.querySelector('cds-toast');
    if (!this.toastWrapper) {
      const body = document.querySelector('body');
      this.toastWrapper = document.createElement('cds-toast');
      body.append(this.toastWrapper);
    }
  }
  show(itemConfig) {
    const item = this.createItem(itemConfig);
    this.toastWrapper.appendChild(item);
  }
  createItem(itemConfig) {
    this.toastWrapper.setAttribute('position', itemConfig.position);
    const item = document.createElement('cds-toast-item');
    item.innerHTML = '';
    item.setAttribute('type', itemConfig.type);
    item.setAttribute('toast-title', itemConfig.title);
    if (itemConfig.progress) {
      item.setAttribute('progress', itemConfig.progress.toString());
    }
    if (itemConfig.timer) {
      item.setAttribute('timer', itemConfig.timer.toString());
    }
    if (itemConfig.text) {
      item.innerHTML = sanitizeHTML(itemConfig.text);
      item.setAttribute('has-description', 'true');
      item.setAttribute('text', itemConfig.text);
    }
    if (itemConfig.title) {
      item.setAttribute('has-title', 'true');
    }
    return item;
  }
}
export { ToastController as T };